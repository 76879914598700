export default theme => ({
  boxContainer: {
    marginTop: theme.spacing(6),
  },
  curbsideSubmittedContainer: {
    margin: '0 auto',
    maxWidth: '800px',
  },
  submittedContainer: {
    margin: '0 auto',
    marginBottom: theme.spacing(-12),
    maxWidth: '600px',
  },
});
