import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Box, Typography } from '@popmenu/common-ui';

import { formatPhone } from '../../../../utils/utils';
import { withStyles } from '../../../../utils/withStyles';
import styles from './styles';

const MenuItemCartNotifications = ({ classes, menuItemCart }) => {
  if (menuItemCart.cartType !== 'default_cart_type' && menuItemCart.cartType !== 'dine_in_cart_type') {
    return null;
  }
  // handle curbside orders
  if (menuItemCart.cartType === 'default_cart_type' && menuItemCart.fulfillmentType === 'pickup_fulfillment_type' &&
    menuItemCart.pickupType === 'curbside_pickup_type') {
    return null;
  }
  if (!menuItemCart.enableTextMessageNotifications && !menuItemCart.enableEmailNotifications) {
    return null;
  }

  return (
    <React.Fragment>
      <br />
      <br />
      <Typography className={classes.sectionHeading}>
        <FormattedMessage
          id="consumer.ordering.notifications.heading"
          defaultMessage="Order Update Notifications"
        />
      </Typography>
      <Box display="flex" flexDirection="column">
        {menuItemCart.enableEmailNotifications && (
          <Box marginBottom={menuItemCart.enableTextMessageNotifications ? 2 : 0}>
            <FormattedMessage
              id="consumer.ordering.notifications.email"
              defaultMessage="Email: {email}"
              values={{ email: menuItemCart.email }}
            />
          </Box>
        )}
        {menuItemCart.enableTextMessageNotifications && (
          <Typography>
            <FormattedMessage
              id="consumer.ordering.notifications.text"
              defaultMessage="Text: {phone}"
              values={{ phone: formatPhone(menuItemCart.phone) }}
            />
          </Typography>
        )}
      </Box>
    </React.Fragment>
  );
};

MenuItemCartNotifications.propTypes = {
  classes: PropTypes.object.isRequired,
  menuItemCart: PropTypes.shape({
    cartType: PropTypes.string,
    enableEmailNotifications: PropTypes.bool,
    enableTextMessageNotifications: PropTypes.bool,
    fulfillmentType: PropTypes.string,
    id: PropTypes.number,
    pickupType: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(MenuItemCartNotifications);
