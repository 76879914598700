import React, { useEffect } from 'react';
import { Box, Link, Icon, Typography } from '@popmenu/common-ui';
import { ArrowRight, CheckCircle, Home, Reply, Truck } from '@popmenu/web-icons';
import { compose } from '@shakacode/recompose';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { createEvent } from '~/utils/eventable';
import { scrollTo, formatPhone } from '../../../../utils/utils';
import { withRestaurant } from '../../../../utils/withRestaurant';
import { withStyles } from '../../../../utils/withStyles';
import styles from './style';
import CarSVG from '../../../../assets/svg/car.svg';
import ParkedCarSVG from '../../../../assets/svg/Park_Car.svg';
import TakeoutSVG from '../../../../assets/svg/TakeoutBG.svg';
import MenuItemCartNotifications from '../MenuItemCartNotifications';
import { withIntl } from '../../../../utils/withIntl';
import { AH } from '../../../shared/AccessibleHeading';

const MenuItemCartSubmitted = ({ classes, menuItemCart, restaurant, t }) => {
  useEffect(() => {
    if (menuItemCart.fulfillmentType !== 'dine_in_fulfillment_type') {
      createEvent({
        eventableType: 'OnlineOrdering',
        eventType: 'thank_you_order_page',
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const sectionTop = document.getElementsByClassName('olo-section-start')[0];
    if (sectionTop) {
      sectionTop.focus();
      scrollTo(sectionTop);
    }
  }, []);

  const onlineOrderingConfirmationTitle = (
    restaurant?.featureFlags?.road_show_ordering ? (
      <AH typography className={classes.heading} component="h2" variant="h4">
        <FormattedMessage
          id="consumer.ordering.confirmation_title"
          defaultMessage="Thank you!"
        />
      </AH>
    ) : (
      <AH typography className={classes.heading} component="h2" variant="h4">
        <FormattedMessage
          id="consumer.ordering.confirmation_title"
          defaultMessage="Thank you for your order!"
        />
      </AH>
    )
  );

  const onlineOrderingConfirmationBody = (
    restaurant?.featureFlags?.road_show_ordering ? (
      <React.Fragment>
        <Typography>
          <FormattedMessage
            id="consumer.ordering.default_confirmation_body"
            defaultMessage="Your order will be available at the pick-up station shortly."
          />
        </Typography>
        <br />
        <Typography>
          <FormattedMessage
            id="consumer.ordering.default_confirmation_body"
            defaultMessage="Visit Popmenu at Booth 5670 to learn more about their award winning food service technology. For media inquires contact {email_link}."
            values={{
              email_link: (
                // eslint-disable-next-line react/jsx-no-literals
                <Link href="mailto:Jennifer.Grasz@popmenu.com" style={{ display: 'initial' }}>
                  Jennifer.Grasz@popmenu.com
                </Link>
              ),
            }}
          />
        </Typography>
      </React.Fragment>
    ) : (
      <Typography>
        <FormattedMessage
          id="consumer.ordering.default_confirmation_body"
          defaultMessage="Your order has been sent to {restaurant_name}! Please note that your order has been placed but is not yet confirmed. Look out for a confirmation email shortly."
          values={{
            restaurant_name: restaurant.name,
          }}
        />
      </Typography>
    )
  );

  if (menuItemCart.fulfillmentType === 'dine_in_fulfillment_type') {
    return (
      <React.Fragment>
        <div className={classes.curbsideHeading}>
          <Icon className={classes.checkmarkIcon} icon={CheckCircle} />
        </div>
        <AH typography className={classes.heading} component="h2" variant="h4">
          <FormattedMessage
            id="consumer.ordering.confirmation_close_tab_title"
            defaultMessage="Your tab has been closed!"
          />
        </AH>
        <Typography className={classes.heading}>
          <FormattedMessage
            id="consumer.ordering.default_confirmation_dine_in_body"
            defaultMessage={`Thanks for dining with us at ${restaurant.name}, we hope you enjoyed your time here. Your receipt will be emailed to you shortly.`}
            values={{
              restaurant_name: restaurant.name,
            }}
          />
        </Typography>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {onlineOrderingConfirmationTitle}
        {onlineOrderingConfirmationBody}
        <br />
        <div className={classes.locationAddress}>
          {menuItemCart.fulfillmentType === 'pickup_fulfillment_type' && (
            <React.Fragment>
              {menuItemCart.pickupType === 'curbside_pickup_type' ? (
                // curbside pickup type
                <div>
                  <div className={classes.curbsideHeading}>
                    <Icon className={classes.carIcon} icon={CarSVG} />
                    <strong>{t('models.menu_item_cart.curbside_pickup')}</strong>
                  </div>
                  <div className={classes.curbsideAddressDirections}>
                    <strong>{menuItemCart.location.fullAddress}</strong>
                    <br />
                    {menuItemCart.location.pickupCurbsideDescription}
                  </div>
                  <div className={classes.curbsideInfoContainer}>
                    <div className={classes.curbsideInfoItem}>
                      <Icon className={classes.curbsideIcon} icon={ParkedCarSVG} />
                      <Typography align="center">
                        <FormattedMessage
                          id="consumer.ordering.curbside_confirmation_arrive"
                          defaultMessage="1. Arrive at <b>{restaurant_name}</b>"
                          values={{
                            b: chunks => <b>{chunks}</b>,
                            restaurant_name: restaurant.name,
                          }}
                        />
                      </Typography>
                    </div>
                    <Icon className={classes.curbsideArrow} icon={ArrowRight} />
                    <div className={classes.curbsideInfoItem}>
                      <Box className={classes.curbsideIconContainer} width={100} height={100} borderRadius={100} display="flex" justifyContent="center" alignItems="center" fontSize={75} margin="0 auto 24px">
                        <Icon icon={Reply} />
                      </Box>
                      <Typography align="center">
                        <FormattedMessage
                          id="consumer.ordering.curbside_confirmation_text"
                          defaultMessage="2. Reply text with <b>&quot;1&quot;</b> {curbsidePhone, selectordinal,
                            one {or call <b>{phoneNumber}</b>}
                            other {}
                          }"
                          values={{
                            b: chunks => <b>{chunks}</b>,
                            curbsidePhone: menuItemCart.location.curbsidePhone ? 1 : 0,
                            phoneNumber: menuItemCart.location.curbsidePhone && formatPhone(menuItemCart.location.curbsidePhone),
                          }}
                        />
                      </Typography>
                    </div>
                    <Icon className={classes.curbsideArrow} icon={ArrowRight} />
                    <div className={classes.curbsideInfoItem}>
                      <Icon className={classes.curbsideIcon} icon={TakeoutSVG} />
                      <Typography align="center">
                        <FormattedMessage
                          id="consumer.ordering.curbside_confirmation_bring_order"
                          defaultMessage="3. Our staff will bring your order to your vehicle"
                        />
                      </Typography>
                    </div>
                  </div>
                </div>
              ) : (
                // in-store pickup type
                <React.Fragment>
                  <Icon className={classes.locationIcon} icon={Home} />
                  <FormattedMessage id="consumer.ordering.in_store_pickup_type" defaultMessage="Ordered from {restaurant} - {cartLocationName}" values={{ cartLocationName: menuItemCart.location.name, restaurant: restaurant.name }} />
                  <br />
                  {menuItemCart.location.fullAddress}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {menuItemCart.fulfillmentType === 'delivery_fulfillment_type' && (
            <React.Fragment>
              <Icon className={classes.locationIcon} icon={Truck} />
              <FormattedMessage id="consumer.ordering.delivery_to" defaultMessage="Delivery To" />
              <br />
              {menuItemCart.deliveryFullAddress}
            </React.Fragment>
          )}
        </div>
        <MenuItemCartNotifications
          menuItemCart={menuItemCart}
        />
      </React.Fragment>
    );
  }
};

MenuItemCartSubmitted.propTypes = {
  classes: PropTypes.object.isRequired,
  menuItemCart: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  restaurant: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default compose(
  withRestaurant,
  withStyles(styles),
  withIntl,
)(MenuItemCartSubmitted);
