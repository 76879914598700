import { alpha } from '@material-ui/core';

export default theme => ({
  carIcon: {
    fill: theme.palette.primary.main,
    height: 'auto',
    marginRight: theme.spacing(2),
    maxHeight: '40px',
    width: 'auto',
  },
  checkmarkIcon: {
    color: theme.palette.green.main,
    height: '10rem',
    width: '10rem',
  },
  curbsideAddressDirections: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  curbsideArrow: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    height: '50px',
    marginTop: theme.spacing(5),
    width: '50px',
  },
  curbsideHeading: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  curbsideIcon: {
    '& rect, & path': {
      fill: theme.palette.primary.main,
    },
    height: '100px',
    margin: `0px auto ${theme.spacing(3)}px auto`,
  },
  curbsideIconContainer: {
    '& svg': {
      paddingRight: 10,
    },
    backgroundColor: alpha(theme.palette.primary.main, 0.076),
  },
  curbsideInfoContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    display: 'flex',
    margin: theme.spacing(2),
  },
  curbsideInfoItem: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
      width: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    width: '190px',
  },
  heading: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  locationAddress: {
    alignItems: 'center',
    backgroundColor: '#E7E7E7',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  locationIcon: {
    fontSize: '3em',
    marginRight: theme.spacing(2),
  },
});
