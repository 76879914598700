import React, { Fragment } from 'react';
import { Paper, Button, Grid } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';

import { Link, useParams } from 'react-router-dom';
import { useQuery } from '~/lazy_apollo/client';
import menuItemCartNotifications from '../../libs/gql/queries/menu_item_carts/menuItemCartNotificationsQuery.gql';
import Loading from '../../shared/Loading';
import MenuItemCartSubmitted from '../menus/cart/MenuItemCartSubmitted';
import { classNames, makeStyles } from '../../utils/withStyles';
import styles from './styles';

const useStyles = makeStyles(styles);

const ConfirmationPage = ({ restaurant }) => {
  const classes = useStyles();
  const { id } = useParams();
  const { data, loading } = useQuery(menuItemCartNotifications,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id: parseInt(id, 10) },
    });

  if (loading) {
    return <Loading size="lg" />;
  }

  const { menuItemCart } = data;
  if (menuItemCart.phone || menuItemCart.email) {
    return (
      <Fragment>
        <Grid container>
          <Grid item xs={12} className={classes.boxContainer}>
            <Paper
              className={classNames(
                menuItemCart.pickupType === 'curbside_pickup_type' ?
                  classes.curbsideSubmittedContainer : classes.submittedContainer,
                'olo-section-start',
                'pm-cart-checkout-confirmation',
              )}
              component="section"
              elevation={3}
              tabIndex={-1}
              legacyStyles
            >
              <React.Fragment>
                <MenuItemCartSubmitted
                  restaurant={restaurant}
                  menuItemCart={menuItemCart}
                />
                <br />
                <Button fullWidth color="primary" component={Link} size="large" to="/" variant="contained">
                  <FormattedMessage id="consumer.ordering.submitted_confirmation" defaultMessage="Okay" />
                </Button>
              </React.Fragment>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  } else {
    return <Loading size="lg" />;
  }
};
export default ConfirmationPage;
